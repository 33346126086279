import "core-js/modules/es.array.includes.js";
import { colors } from '@aftersale-next/ui';
export function statusOf(statusCode) {
  if (!statusCode) return colors.textForeground;
  var reds = [4, 6, 8, 9, 92, 93];
  var yellows = [16];
  var greens = [12, 13, 32];
  var blues = [1, 2, 3, 5, 7, 10, 11, 14, 15, 17, 18, 19, 20, 21, 22, 24, 30, 31];
  if (reds.includes(statusCode)) return 'red';
  if (yellows.includes(statusCode)) return colors.textForeground;
  if (greens.includes(statusCode)) return colors.textForeground;
  if (blues.includes(statusCode)) return colors.textForeground;
}