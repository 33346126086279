import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
var MAX_NAME_SIZE = 25;
export var getProductsNames = function getProductsNames(products) {
  return products.map(function (p) {
    return p.name;
  }).map(function (name) {
    return name.length > MAX_NAME_SIZE ? "".concat(name.slice(0, MAX_NAME_SIZE), "...") : name;
  }).join(', ');
};