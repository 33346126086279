export var RECEIVED = 'package_received';
export var PICKED = 'package_picked';
export var NOT_RECEIVED = 'package_not_received';
export var HELP = 'help';
export var CHANGE_CHANNEL = 'change_channel';
export var SELECT_CHANNEL = 'select_channel';
export var ACCEPT_RETENTION = 'accept_retention';
export var REFUSE_RETENTION = 'refuse_retention';
export var NECESSARY_ACTION = 'necessary_action';
export var RETURN_OR_EXCHANGE = 'return_or_exchange';
export var ACCEPT_NEW_ESTIMATED_DELIVERY_DATE = 'accept_new_estimated_delivery_date';
export var REFUSE_NEW_ESTIMATED_DELIVERY_DATE = 'refuse_new_estimated_delivery_date';
export var RETENTION_ACTIONS = [ACCEPT_RETENTION, REFUSE_RETENTION];