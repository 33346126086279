import "core-js/modules/es.array.every.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.some.js";
import "core-js/modules/es.string.includes.js";
import { FINISHED_STATUS } from '../config/constants/status';
import { useTrackingQuery } from './use-tracking-query';
export function useAllPackagesDelivered() {
  var _useTrackingQuery = useTrackingQuery(),
      data = _useTrackingQuery.data;

  if (!(data !== null && data !== void 0 && data.packages)) return false;
  if (!data.packages.length) return false;
  return data.packages.every(function (pkg) {
    return pkg.events.some(function (_ref) {
      var code = _ref.code;
      return FINISHED_STATUS.includes(code);
    });
  });
}