import { useCurrentTracking } from './use-current-tracking';
export function useDelivery() {
  var tracking = useCurrentTracking();
  var courier = tracking === null || tracking === void 0 ? void 0 : tracking.courier;
  var trackingCode = tracking === null || tracking === void 0 ? void 0 : tracking.code;
  var deliveryEstimatedDate = tracking === null || tracking === void 0 ? void 0 : tracking.estimatedDeliveryDate;
  var newDeliveryEstimatedDate = tracking === null || tracking === void 0 ? void 0 : tracking.newEstimatedDeliveryDate;
  var logisticProviderName = tracking === null || tracking === void 0 ? void 0 : tracking.logisticProviderName;
  return {
    courier: courier,
    trackingCode: trackingCode,
    deliveryEstimatedDate: deliveryEstimatedDate,
    newDeliveryEstimatedDate: newDeliveryEstimatedDate,
    logisticProviderName: logisticProviderName,
    invoiceUrl: tracking === null || tracking === void 0 ? void 0 : tracking.invoiceUrl
  };
}