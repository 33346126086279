import "core-js/modules/es.array.find.js";
import { usePackageIndex } from './use-package-index';
import { useTrackingQuery } from './use-tracking-query';
export function useCurrentTracking() {
  var _data$packages;

  var _useTrackingQuery = useTrackingQuery(),
      data = _useTrackingQuery.data;

  var packageIndex = usePackageIndex();
  return data === null || data === void 0 ? void 0 : (_data$packages = data.packages) === null || _data$packages === void 0 ? void 0 : _data$packages.find(function (pkg) {
    return pkg.packageIndex === packageIndex;
  });
}