import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.string.includes.js";
import _defineProperty from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useRouter } from 'next/router';
import { useFeedback } from './use-feedback';
import { useRatingMutation } from './use-rating-mutation';
import { useAllPackagesDelivered } from './use-all-packages-delivered';
import { useEcommerceFeatures } from '@aftersale-next/atoms';
import { ECOMMERCE_FEATURES } from '../config/constants/ecommerce-features';
export function useRating(type) {
  var _router$query, _currentRating$rating;

  var mutation = useRatingMutation();
  var storedRating = useFeedback();
  var router = useRouter();
  var allPackagesDelivered = useAllPackagesDelivered();

  var _useEcommerceFeatures = useEcommerceFeatures(),
      _useEcommerceFeatures2 = _slicedToArray(_useEcommerceFeatures, 1),
      features = _useEcommerceFeatures2[0];

  var evaluationIsEnabled = features.includes(ECOMMERCE_FEATURES.CSAT_EVALUATION);
  var currentRating = storedRating.find(function (rating) {
    return rating.type === type;
  });
  var isReadyToRate = evaluationIsEnabled && allPackagesDelivered && ((currentRating === null || currentRating === void 0 ? void 0 : currentRating.rating) < 3 && !currentRating.commentary || !(currentRating !== null && currentRating !== void 0 && currentRating.rating));
  var queryScore = router === null || router === void 0 ? void 0 : (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query['csat_rate'];
  var rateScore = (_currentRating$rating = currentRating === null || currentRating === void 0 ? void 0 : currentRating.rating) !== null && _currentRating$rating !== void 0 ? _currentRating$rating : queryScore;
  var data = {
    rating: rateScore ? parseInt(rateScore) : undefined,
    commentary: currentRating === null || currentRating === void 0 ? void 0 : currentRating.commentary
  };

  var rate = function rate(data) {
    return mutation.mutate(_objectSpread(_objectSpread({}, data), {}, {
      type: type
    }));
  };

  return {
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    error: mutation.error,
    isReadyToRate: isReadyToRate,
    data: data,
    rate: rate
  };
}