export var createLoginUrl = function createLoginUrl(_ref) {
  var order = _ref.order,
      packageIndex = _ref.packageIndex,
      confirmation = _ref.confirmation,
      isEmbed = _ref.isEmbed,
      csatRate = _ref.csatRate;
  return "/login?order=".concat(order !== null && order !== void 0 ? order : '', "&confirmation=").concat(confirmation !== null && confirmation !== void 0 ? confirmation : '').concat(packageIndex ? "&package_index=".concat(packageIndex) : '').concat(isEmbed ? '&embed=true' : '').concat(csatRate ? "&csat_rate=".concat(csatRate) : '');
};
export var createTrackingUrl = function createTrackingUrl(_ref2) {
  var order = _ref2.order,
      packageIndex = _ref2.packageIndex,
      confirmation = _ref2.confirmation,
      isEmbed = _ref2.isEmbed,
      csatRate = _ref2.csatRate;
  return "/tracking?order=".concat(order !== null && order !== void 0 ? order : '', "&confirmation=").concat(confirmation !== null && confirmation !== void 0 ? confirmation : '').concat(packageIndex ? "&package_index=".concat(packageIndex) : '').concat(isEmbed ? '&embed=true' : '').concat(csatRate ? "&csat_rate=".concat(csatRate) : '');
};
export var createChannelUrl = function createChannelUrl(_ref3) {
  var order = _ref3.order,
      packageIndex = _ref3.packageIndex,
      confirmation = _ref3.confirmation,
      isEmbed = _ref3.isEmbed,
      csatRate = _ref3.csatRate;
  return "/channel?order=".concat(order !== null && order !== void 0 ? order : '', "&confirmation=").concat(confirmation !== null && confirmation !== void 0 ? confirmation : '').concat(packageIndex ? "&package_index=".concat(packageIndex) : '').concat(isEmbed ? '&embed=true' : '').concat(csatRate ? "&csat_rate=".concat(csatRate) : '');
};