import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import { useEcommerceUrl } from './use-ecommerce-url';
import { useTrackingQuery } from './use-tracking-query';
import { useAllPackagesDelivered } from './use-all-packages-delivered';
export function useCouponAfterDelivered() {
  var _order$coupon$name, _order$coupon$rules;

  var _useTrackingQuery = useTrackingQuery(),
      order = _useTrackingQuery.data;

  var allPackagesDelivered = useAllPackagesDelivered();
  var redirectLink = useEcommerceUrl({
    utm: 'utmCoupon'
  });
  var dateNow = new Date().getTime();
  if (!(order !== null && order !== void 0 && order.coupon)) return {
    enabled: false
  };
  var enabled = allPackagesDelivered && dateNow <= order.coupon.expirationDate;
  return {
    value: (_order$coupon$name = order.coupon.name) !== null && _order$coupon$name !== void 0 ? _order$coupon$name : '',
    rules: (_order$coupon$rules = order.coupon.rules) !== null && _order$coupon$rules !== void 0 ? _order$coupon$rules : '',
    enabled: enabled,
    redirectLink: redirectLink
  };
}