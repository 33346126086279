import _defineProperty from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useConfirmation, useOrder, useXHost } from '@aftersale-next/atoms';
import { useDoFeedbackMutation } from '@aftersale-next/graphql';
export function useRatingMutation(params) {
  var _result$data;

  var _useOrder = useOrder(),
      _useOrder2 = _slicedToArray(_useOrder, 1),
      order = _useOrder2[0];

  var _useConfirmation = useConfirmation(),
      _useConfirmation2 = _slicedToArray(_useConfirmation, 1),
      confirmation = _useConfirmation2[0];

  var _useXHost = useXHost(),
      _useXHost2 = _slicedToArray(_useXHost, 1),
      xhost = _useXHost2[0];

  var result = useDoFeedbackMutation({
    onSuccess: function onSuccess() {
      if (typeof (params === null || params === void 0 ? void 0 : params.onSuccess) === 'function') params.onSuccess();
    },
    onError: params === null || params === void 0 ? void 0 : params.onError
  });

  var handleMutate = function handleMutate(_ref) {
    var rating = _ref.rating,
        commentary = _ref.commentary,
        type = _ref.type;
    return result.mutate({
      host: xhost,
      order: order,
      confirmation: confirmation,
      data: {
        rating: rating,
        commentary: commentary,
        type: type
      }
    });
  };

  return _objectSpread(_objectSpread({}, result), {}, {
    mutate: handleMutate,
    data: (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.feedback
  });
}