import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useEcommerceUrlSettings } from '@aftersale-next/atoms';
import { generateUtmUrl } from '@aftersale-next/utils';
import { useTranslation } from 'react-i18next';
export function useEcommerceUrl(_ref) {
  var utm = _ref.utm;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var _useEcommerceUrlSetti = useEcommerceUrlSettings(),
      _useEcommerceUrlSetti2 = _slicedToArray(_useEcommerceUrlSetti, 1),
      ecommerceUrlSettings = _useEcommerceUrlSetti2[0];

  var baseUrl = ecommerceUrlSettings !== null && ecommerceUrlSettings !== void 0 && ecommerceUrlSettings.url ? "https://".concat(ecommerceUrlSettings.url) : '';

  if (utm && ecommerceUrlSettings.utm[utm]) {
    return generateUtmUrl({
      baseUrl: baseUrl,
      source: t('UTM_SOURCE'),
      medium: ecommerceUrlSettings.utm[utm],
      campaign: t('UTM_CAMPAIGN')
    });
  }

  return baseUrl;
}