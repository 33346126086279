import _defineProperty from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useConfirmation, useEcommerceEnabled, useOrder, usePoolingInterval, useXHost } from '@aftersale-next/atoms';
import { useGetTrackingsQuery } from '@aftersale-next/graphql';
var MINUTE = 1000 * 60;
export function useTrackingQuery(params) {
  var _result$data;

  var _useOrder = useOrder(),
      _useOrder2 = _slicedToArray(_useOrder, 1),
      order = _useOrder2[0];

  var _useConfirmation = useConfirmation(),
      _useConfirmation2 = _slicedToArray(_useConfirmation, 1),
      confirmation = _useConfirmation2[0];

  var _useXHost = useXHost(),
      _useXHost2 = _slicedToArray(_useXHost, 1),
      xhost = _useXHost2[0];

  var _usePoolingInterval = usePoolingInterval(),
      _usePoolingInterval2 = _slicedToArray(_usePoolingInterval, 1),
      pooling = _usePoolingInterval2[0];

  var _useEcommerceEnabled = useEcommerceEnabled(),
      _useEcommerceEnabled2 = _slicedToArray(_useEcommerceEnabled, 1),
      enabled = _useEcommerceEnabled2[0];

  var result = useGetTrackingsQuery({
    order: order,
    confirmation: confirmation,
    host: xhost
  }, {
    retry: false,
    refetchInterval: params !== null && params !== void 0 && params.disableRefetchInterval ? false : pooling !== null && pooling !== void 0 ? pooling : 5 * MINUTE,
    onSuccess: params === null || params === void 0 ? void 0 : params.onSuccess,
    onError: params === null || params === void 0 ? void 0 : params.onError,
    enabled: !!order && !!confirmation && !!enabled
  });
  return _objectSpread(_objectSpread({}, result), {}, {
    data: (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.tracking
  });
}