import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.string.includes.js";
import _slicedToArray from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useEcommerceFeatures } from '@aftersale-next/atoms';
import { ECOMMERCE_FEATURES } from '../config/constants/ecommerce-features';
import { useDelivery } from './use-delivery';

var transformToLocaleDate = function transformToLocaleDate(date) {
  return new Date(date).toLocaleDateString();
};

export function useFormattedDeliveryDate() {
  var _useDelivery = useDelivery(),
      newDeliveryEstimatedDate = _useDelivery.newDeliveryEstimatedDate,
      deliveryEstimatedDate = _useDelivery.deliveryEstimatedDate;

  var _useEcommerceFeatures = useEcommerceFeatures(),
      _useEcommerceFeatures2 = _slicedToArray(_useEcommerceFeatures, 1),
      features = _useEcommerceFeatures2[0];

  var shouldUseNewDelivery = features.includes(ECOMMERCE_FEATURES.DELIVERY_DELAY);
  if (shouldUseNewDelivery && newDeliveryEstimatedDate) return transformToLocaleDate(newDeliveryEstimatedDate);
  if (deliveryEstimatedDate) return transformToLocaleDate(deliveryEstimatedDate);
  return null;
}