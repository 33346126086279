import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
export var capitalize = function capitalize(text) {
  var words = text.split(' ');

  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  var capitalizedText = words.join(' ');
  return capitalizedText;
};
export var replaceAllUnderscoreToSpace = function replaceAllUnderscoreToSpace(text) {
  return text.replace(/_/g, ' ');
};