import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.slice.js";
import { useChannels } from './use-channels';
import { useCustomer } from './use-customer';
export function useUserInfo(info) {
  var _customer$document;

  var customer = useCustomer();
  var channels = useChannels();

  var findChannelData = function findChannelData(channel) {
    var selectedChannel = channels.find(function (item) {
      return item.channel === channel;
    });
    if (!selectedChannel) return {};
    return selectedChannel.data;
  };

  if (!customer) return '';

  switch (info) {
    case 'sms':
      return findChannelData('sms').phone ? findChannelData('sms').phone.slice(2) : customer !== null && customer !== void 0 && customer.phone ? customer.phone.slice(2) : '';

    case 'whatsapp':
      return findChannelData('whatsapp').phone ? findChannelData('whatsapp').phone.slice(2) : customer !== null && customer !== void 0 && customer.phone ? customer.phone.slice(2) : '';

    case 'email':
      return findChannelData('email').email ? findChannelData('email').email : customer !== null && customer !== void 0 && customer.email ? customer.email : '';

    case 'phone':
      return customer !== null && customer !== void 0 && customer.phone ? customer.phone.slice(2) : '';

    case 'document':
      return (_customer$document = customer === null || customer === void 0 ? void 0 : customer.document) !== null && _customer$document !== void 0 ? _customer$document : '';

    default:
      return '';
  }
}